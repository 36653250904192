import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import BlogPageHeaderListings from "../components/BlogPageHeader/BlogPageHeaderListings";
import BlogPageListing from "../components/BlogPageListing/BlogPageListing";
import BlogPagination from "../components/BlogPagination/BlogPagination";
import Footer from "../components/Footer/Footer";

const BlogCategoryPage = ({
  data,
  pageContext: {
    pagePath,
    nextPagePath,
    previousPagePath,
    totalPages,
    currentPage,
    seo,
    categoryName,
  },
}) => {
  const posts = data.allWpPost.nodes;
  if (!posts.length) {
    return (
      <Layout>
        <Header />
        <p>
          No blog posts found. Add posts to your WordPress site and they`ll
          appear here!
        </p>
        {/* <Footer /> */}
      </Layout>
    );
  }

  return (
    <>
      <Seo post={seo} title={seo.title} />
      <Helmet
        htmlAttributes={{
          lang: "en-US",
        }}
      />
      <Layout>
        <Header />
        <BlogPageHeaderListings name={categoryName} category={true} />
        <BlogPageListing data={posts} />
        <BlogPagination
          pagePath={pagePath}
          nextPagePath={nextPagePath}
          previousPagePath={previousPagePath}
          currentPage={currentPage}
          totalPages={totalPages}
        />
        <Footer />
      </Layout>
    </>
  );
};

export default BlogCategoryPage;

export const pageQuery = graphql`
  query WordPressPostsByCategory(
    $offset: Int!
    $postsPerPage: Int!
    $id: String!
  ) {
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        blogSinglePost {
          author {
            firstName
            lastName
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  height: 500
                  width: 600
                )
              }
            }
            altText
          }
        }
        categories {
          nodes {
            uri
            name
          }
        }
        author {
          node {
            uri
            name
            firstName
            lastName
          }
        }
      }
    }
  }
`;
