import React from "react";
// import uniqueId from "lodash/uniqueId";
// import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
// import IsrLabel from "../IsrLabel/IsrLabel";
import IsrButton from "../IsrButton/IsrButton";
import Spacer from "../Spacer/Spacer";
import "./BlogPageHeader.scss";

const BlogPageHeaderAuthor = ({ name, category }) => {
  return (
    <section className="blog-page-header">
      <div className="container">
        <Spacer size={100} mobileSize={50} />
        <div className="row">
          <div className="col-12 col-lg-7">
            <h1 className="h2">
              {category ? "Category" : "Author"}: {name}
            </h1>
            <hr size="3" className="divider" />
            <article className="featured-article">
              <IsrButton type="button" variant="primary" size="md">
                <Link to={`/blog/`}>
                  <strong>Back to blog</strong>
                </Link>
              </IsrButton>
            </article>
          </div>
          <div className="col-12 col-lg-5" />
        </div>
        <Spacer size={0} mobileSize={30} />
      </div>
    </section>
  );
};

export default BlogPageHeaderAuthor;
